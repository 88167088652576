import React from "react"
import { Box } from "rebass"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Title from "../components/title"
import Enumeration from "../components/enumeration"

const items = [
  {
    title: 'Erkrankung des Stütz- und Bewegungsapparates',
    description: 'Rückenbeschwerden, Bandscheibenvorfall, Arthrose, Arthritis, Tennisellenbogen etc.'
  },
  {
    title: 'Psychische und psychosomatische Störungen',
    description: 'Psychische und psychosomatische Störungen'
  },
  {
    title: 'Neurologische Störungen',
    description: 'Kopfschmerzen, Migräne, Trigeminusneuralgien, Parästhesien, Tinnitus'
  },
  {
    title: 'Atemwegserkrankungen',
    description: 'Bronchitis, Asthma Bronchiale, allergische Rhinitis, Sinusitis, Tonsillitis'
  },
  {
    title: 'Hauterkrankungen',
    description: 'Allergien, Neurodermitis, Psoriasis, Herpes Zoster, Juckreiz'
  },
  {
    title: 'Magen-Darmerkrankungen',
    description: 'Gastritis, Colon irritable, Colitis, Obstipation, Diarrhö, Morbus Crohn, Reizdarm'
  },
  {
    title: 'Funktionelle Störung des Urogenitaltraktes'
  },
  {
    title: 'Augenerkrankungen',
  },
  {
    title: 'Suchtprobleme',
    description: 'Entgiftungsbehandlungen bei Suchtkrankheiten, Raucherentwöhnung, Adipositas'
  },
  {
    title: 'Gewichtsregulierung',
  },
  {
    title: 'Gynäkologische Erkrankungen',
    description: 'Menstruations- und Zyklusstörungen, Klimakterische Beschwerden, Fertilitätsstörungen bei Kinderwunsch, Stillprobleme'
  },
]

const AnwendungsgebietePage = () => (
  <Layout>
    <SEO title="Anwendungsgebiete" />
    <Hero>
      <Title>Anwendungsgebiete</Title>
      <p>Bei der Aufzählung einzelner Krankheitsbilder oder isolierter Symptome ist zu berücksichtigen, dass die Methoden der TCM stets den ganzen Menschen in den Blick nehmen und nicht nur eine lokale Störung.</p>
      <p>Die Weltgesundheitsorganisation (WHO) hat eine Liste von Erkrankungen veröffentlicht, die mit Akupunktur erfolgreich behandelt werden können:</p>
    </Hero>
    <Box px={[3, 5]} py={4}>
      <Enumeration items={items} />
    </Box>
  </Layout>
)

export default AnwendungsgebietePage
