import PropTypes from "prop-types"
import React from "react"
import { Text } from "rebass"
import scrollTo from 'gatsby-plugin-smoothscroll';

const Enumeration = ({ items }) => (
  <ul style={{ margin: 0 }}>
    {items.map((item, index) => (
      <li
        key={`enumeration-${index}`}
        style={{
          margin: '18px 0',
          listStyle: 'none'
        }}>
        <Text
          onClick={() => item.id && scrollTo(`#${item.id}`)}
          fontSize={2}
          color='#a02118'
          style={{ cursor: item.id && 'pointer' }}
        >
          <div style={{
            display: 'inline-block',
            borderStyle: 'solid',
            borderColor: '#a02118',
            borderWidth: '0 2px 2px 0',
            transform: 'rotate(-45deg)',
            padding: 4,
            marginRight: 9
          }} />
          {item.title}
        </Text>
        <span>{item.description}</span>
      </li>
    ))}
  </ul>
)

Enumeration.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

Enumeration.defaultProps = {
  items: [],
}

export default Enumeration
